.timeline {
  position: relative;
  display: flex
}

:where(.timeline > li) {
  position: relative;
  display: grid;
  flex-shrink: 0;
  align-items: center;
  grid-template-rows: var(--timeline-row-start, minmax(0, 1fr)) auto var(--timeline-row-end,
      minmax(0, 1fr));
  grid-template-columns: var(--timeline-col-start, minmax(0, 1fr)) auto var(--timeline-col-end,
      minmax(0, 1fr))
}

.timeline>li>hr {
  width: 100%;
  border-width: 0px
}

:where(.timeline > li > hr):first-child {
  grid-column-start: 1;
  grid-row-start: 2
}

:where(.timeline > li > hr):last-child {
  grid-column-start: 3;
  grid-column-end: none;
  grid-row-start: 2;
  grid-row-end: auto
}

.timeline-start {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  margin: 0.25rem;
  align-self: flex-end;
  justify-self: center
}

.timeline-middle {
  grid-column-start: 2;
  grid-row-start: 2
}

.timeline-end {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  margin: 0.25rem;
  align-self: flex-start;
  justify-self: center
}

.timeline hr {
  height: 0.25rem
}

:where(.timeline hr) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b3, oklch(var(--b3)/var(--tw-bg-opacity)))
}

:where(.timeline:has(.timeline-middle) hr):first-child {
  border-start-end-radius: var(--rounded-badge, 1.9rem);
  border-end-end-radius: var(--rounded-badge, 1.9rem);
  border-start-start-radius: 0px;
  border-end-start-radius: 0px
}

:where(.timeline:has(.timeline-middle) hr):last-child {
  border-start-start-radius: var(--rounded-badge, 1.9rem);
  border-end-start-radius: var(--rounded-badge, 1.9rem);
  border-start-end-radius: 0px;
  border-end-end-radius: 0px
}

:where(.timeline:not(:has(.timeline-middle)) :first-child hr:last-child) {
  border-start-start-radius: var(--rounded-badge, 1.9rem);
  border-end-start-radius: var(--rounded-badge, 1.9rem);
  border-start-end-radius: 0px;
  border-end-end-radius: 0px
}

:where(.timeline:not(:has(.timeline-middle)) :last-child hr:first-child) {
  border-start-end-radius: var(--rounded-badge, 1.9rem);
  border-end-end-radius: var(--rounded-badge, 1.9rem);
  border-start-start-radius: 0px;
  border-end-start-radius: 0px
}

.timeline-box {
  border-radius: var(--rounded-box, 1rem);
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b3, oklch(var(--b3)/var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1)/var(--tw-bg-opacity)));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.timeline-vertical>li>hr {
  width: 0.25rem
}

:where(.timeline-vertical:has(.timeline-middle) > li > hr):first-child {
  border-bottom-right-radius: var(--rounded-badge, 1.9rem);
  border-bottom-left-radius: var(--rounded-badge, 1.9rem);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px
}

:where(.timeline-vertical:has(.timeline-middle) > li > hr):last-child {
  border-top-left-radius: var(--rounded-badge, 1.9rem);
  border-top-right-radius: var(--rounded-badge, 1.9rem);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px
}

:where(.timeline-vertical:not(:has(.timeline-middle)) :first-child > hr:last-child) {
  border-top-left-radius: var(--rounded-badge, 1.9rem);
  border-top-right-radius: var(--rounded-badge, 1.9rem);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px
}

:where(.timeline-vertical:not(:has(.timeline-middle)) :last-child > hr:first-child) {
  border-bottom-right-radius: var(--rounded-badge, 1.9rem);
  border-bottom-left-radius: var(--rounded-badge, 1.9rem);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px
}

.timeline-horizontal>li>hr {
  height: 0.25rem
}

:where(.timeline-horizontal:has(.timeline-middle) > li > hr):first-child {
  border-start-end-radius: var(--rounded-badge, 1.9rem);
  border-end-end-radius: var(--rounded-badge, 1.9rem);
  border-start-start-radius: 0px;
  border-end-start-radius: 0px
}

:where(.timeline-horizontal:has(.timeline-middle) > li > hr):last-child {
  border-start-start-radius: var(--rounded-badge, 1.9rem);
  border-end-start-radius: var(--rounded-badge, 1.9rem);
  border-start-end-radius: 0px;
  border-end-end-radius: 0px
}

:where(.timeline-horizontal:not(:has(.timeline-middle)) :first-child > hr:last-child) {
  border-start-start-radius: var(--rounded-badge, 1.9rem);
  border-end-start-radius: var(--rounded-badge, 1.9rem);
  border-start-end-radius: 0px;
  border-end-end-radius: 0px
}

:where(.timeline-horizontal:not(:has(.timeline-middle)) :last-child > hr:first-child) {
  border-start-end-radius: var(--rounded-badge, 1.9rem);
  border-end-end-radius: var(--rounded-badge, 1.9rem);
  border-start-start-radius: 0px;
  border-end-start-radius: 0px
}